.inactive:checked {
  -webkit-box-shadow: 1px 1px 3px 0px rgba(73, 36, 125, 1);
  -moz-box-shadow: 1px 1px 3px 0px rgba(73, 36, 125, 1);
  box-shadow: 1px 1px 3px 0px rgba(73, 36, 125, 1);
}

.shadow {
  box-shadow: 0 0 0 1px #c2c2c2;
}
.shadow:checked {
  box-shadow: 0 0 0 1px #49247d;
}

input[type="radio"]:focus-visible,
input[type="checkbox"]:focus-visible {
  outline: solid 2px #000;
  opacity: 1;
  border: none;
}

.SelectorBlock a {
  text-decoration-line: underline;
  text-underline-offset: 4px;
}
