.accordion .list .accordionPlus {
    display: block;
}
.accordion .list:hover .accordionPlus .circle, .accordion .list:hover .accordionMinus .circle {
    fill: #49247D;
}
.accordion .list:hover .accordionPlus .innerText, .accordion .list:hover .accordionMinus .innerText {
    fill: #FFFFFF;
}
.accordion .list.active .accordionPlus {
    display: none;
}
.accordion .list .accordionMinus {
    display: none;
}
.accordion .list.active .accordionMinus {
    display: block;
}
.accordion .list .content {
    height: 0;
    overflow: hidden;
}
.accordion .list.active .content {
    height: auto;
}