.events-tabSelector .tabHeading.mobile .tabs {
    flex: 1 1 0%;
}

.events-tabSelector .tabHeading .tabs.active {
    background-color: #EDE9F2;
    border: 2px solid #49247D;
    color: #49247D;
}

.events-tabSelector .mobileTabHeading {
    height: 0;
    border: none;
    overflow-y: auto;
}

.events-tabSelector .mobileTabHeading.active {
    height: auto;
    border: inherit;
}

.events-tabSelector .mobileSelectedTab.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.events-tabSelector .mobileSelectedTab .arrowDown {
    display: block;
}
.events-tabSelector .mobileSelectedTab.active .arrowDown {
    display: none;
}
.events-tabSelector .mobileSelectedTab .arrowUp {
    display: none;
}
.events-tabSelector .mobileSelectedTab.active .arrowUp {
    display: block;
}

.carouselWidth {
    flex: 1 0 320px;
}
@media only screen and (min-width: 1200px) {
    .carouselWidth {
        flex: 1 0 380px;
    } 
}
