.quicklinks.mobile .container .arrowDown {
    display: block;
}
.quicklinks.mobile .container.active .arrowDown {
    display: none;
}
.quicklinks.mobile .container .arrowUp {
    display: none;
}
.quicklinks.mobile .container.active .arrowUp {
    display: block;
}
.quicklinks.mobile .container .list {
    height: 0;
    overflow: hidden;
}
.quicklinks.mobile .container.active .list {
    height: auto;
}



.investmentsquicklinks .container .arrowDown {
    display: block;
}
.investmentsquicklinks .container.active .arrowDown {
    display: none;
}
.investmentsquicklinks .container .arrowUp {
    display: none;
}
.investmentsquicklinks .container.active .arrowUp {
    display: block;
}
.investmentsquicklinks .container .list {
    height: 0;
    overflow: hidden;
}
.investmentsquicklinks .container.active .list {
    height: auto;
}