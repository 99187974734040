.tabLayout .tabHeading.mobile .tabs {
    flex: 1 1 0%;
}

.tabLayout .tabHeading .tabs.active {
    background-color: #EDE9F2;
    border-bottom: 3px solid #49247D;
}

.tabLayout .mobileTabHeading {
    height: 0;
    overflow: hidden;
}

.tabLayout .mobileTabHeading.active {
    height: auto;
}

.tabLayout .mobileSelectedTab .arrowDown {
    display: block;
}
.tabLayout .mobileSelectedTab.active .arrowDown {
    display: none;
}
.tabLayout .mobileSelectedTab .arrowUp {
    display: none;
}
.tabLayout .mobileSelectedTab.active .arrowUp {
    display: block;
}
.tabContent {
    display: none;
}
.tabContent.active {
    display: block;
}