.textBlock a {
    color: #49247D;
    text-decoration-line: underline;
    text-underline-offset: 4px;
}
.textBlock a.no-underline {
    text-decoration-line: none;
}

.news-tabSelector .tabHeading.mobile .tabs {
    flex: 1 1 0%;
}

.news-tabSelector .tabHeading .tabs.active {
    background-color: #EDE9F2;
    border: 2px solid #49247D;
    color: #49247D;
}

.news-tabSelector .mobileTabHeading {
    height: 0;
    border: none;
    overflow-y: auto;
}

.news-tabSelector .mobileTabHeading.active {
    height: auto;
    border: inherit;
}

.news-tabSelector .mobileSelectedTab.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.news-tabSelector .mobileSelectedTab .arrowDown {
    display: block;
}
.news-tabSelector .mobileSelectedTab.active .arrowDown {
    display: none;
}
.news-tabSelector .mobileSelectedTab .arrowUp {
    display: none;
}
.news-tabSelector .mobileSelectedTab.active .arrowUp {
    display: block;
}