@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: "Manrope", sans-serif;
        font-optical-sizing: auto;
        font-display: swap;
        src: url(/src/Assets/font/Manrope-VariableFont_wght.ttf) format('woff2');
    }
}

@layer components {
    .layout {
        @apply m-auto max-w-[1440px];
    }
    .layoutArticle {
        @apply m-auto max-w-[994px];
    }
    .layoutSpacing {
        @apply px-24 md:px-40 xl:px-64;
    }
    .text-container h1 {
        @apply text-h2 lg:text-h1 pb-16 text-purple-100;
    }
    .text-container h2 {
        @apply text-h3 lg:text-h2 pb-16 text-purple-100 mt-16;
    }
    .text-container h3 {
        @apply text-2xl lg:text-h3 pb-16 text-purple-100 mt-16;
    }
    .text-container h4 {
        @apply text-lg lg:text-h4 pb-16 font-semibold text-purple-100 mt-16;
    }
    .text-container h5 {
        @apply text-h3 lg:text-h2 pb-16 mt-16;
    }
    .text-container h6 {
        @apply text-2xl lg:text-h3 pb-16 mt-16;
    }
    .sectionSpacing {
        @apply py-64;
    }
    .sectionBottomSpacing {
        @apply pb-64;
    }
    .sectionTopSpacing {
        @apply pt-64;
    }
    .sectionBottomMargin {
        @apply mb-64;
    }
    .dupButton {
        @apply flex justify-center items-center text-base md:text-md rounded-lg text-purple-100 
        px-24 py-16  bg-transparent border-purple-100 border 
      hover:bg-white hover:text-purple-100
      active:bg-neutral-30 active:text-purple-100 active:border-white active:border
      disabled:bg-neutral-30 disabled:border disabled:border-neutral-50 disabled:text-neutral-60 disabled:pointer-events-none
      visited:bg-neutral-30 visited:text-purple-100 visited:border-white visited:border;
    }
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
html {
    font-size: 16px;
    scroll-behavior: smooth;
}
body {
    font-family: "Manrope", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}
body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}
  
body::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #49247d;
}

.text-container a {
    text-decoration-line: underline;
    text-underline-offset: 4px;
}
.text-container > p {
    padding-bottom: 24px;
}

.text-container p span.block.h-24 {
    height: 8px;
}
.text-container code {
    font-size: 14px;
    font-family: "Manrope", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}
.text-container ul, .text-container ol {
    list-style: revert;
    margin: 0 12px;
    padding: 0 16px 24px 16px;
}
.in-pagealerts .text-container > p:last-child {
    padding-bottom: 0;
}
.text-container ul li, .text-container ol li {
    padding-bottom: 12px;
}

.text-container ul li:last-child, .text-container ol li:last-child {
    padding-bottom: 0;
}

.text-container ul > li ul, .text-container ul > li ol {
    padding: 12px 0 0 16px;
}

.text-container ol > li ul, .text-container ol > li ol {
    padding: 12px 0 0 16px;
}


.layoutArticle .tableLayoutForPara > ul, .layoutArticle .tableLayoutForPara > ol, .layoutArticle .tableLayoutForPara > p, .layoutArticle .tableLayoutForPara > h1, .layoutArticle .tableLayoutForPara > h2, .layoutArticle .tableLayoutForPara > h3, .layoutArticle .tableLayoutForPara > h4, .layoutArticle .tableLayoutForPara > h5, .layoutArticl.tableLayoutForPara > h6 {
    width: 100%;
}

@media only screen and (min-width: 768px) {
    .tableLayoutForPara > ul, .tableLayoutForPara > ol, .tableLayoutForPara > p, .tableLayoutForPara > h1, .tableLayoutForPara > h2, .tableLayoutForPara > h3, .tableLayoutForPara > h4, .tableLayoutForPara > h5, .tableLayoutForPara > h6 {
        width: 75%;
    }
}

@media only screen and (min-width: 1280px) {
    .tableLayoutForPara > ul, .tableLayoutForPara > ol, .tableLayoutForPara > p, .tableLayoutForPara > h1, .tableLayoutForPara > h2, .tableLayoutForPara > h3, .tableLayoutForPara > h4, .tableLayoutForPara > h5, .tableLayoutForPara > h6 {
        width: 60%;
    }
}
.text-container .tableWrapper {
    overflow: hidden;
    overflow-x: auto;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    margin: 24px 0;
}
.text-container .tableWrapper.maxct {
    width: max-content;
}

.text-container table {
    border-spacing: 0;
    table-layout: fixed;
}

.text-container table tbody tr th {
    position: relative;
    padding: 14px 16px;
    /* min-width: 80px; */
    background-color: #E8F5F6;
    color: #000000;
    text-align: left;
    /* white-space: nowrap; */
    vertical-align: middle;
}

.text-container table tbody tr th:first-child {
    border-top-left-radius: 4px;
}

.text-container table tbody tr th:last-child {
    border-top-right-radius: 4px;
}

.text-container table tbody tr td {
    padding: 14px 16px;
    /* min-width: 120px; */
    color: #424242;
    text-align: left;
    word-wrap: break-word;
    vertical-align: top;
}

.text-container table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 4px;
}

.text-container table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 4px;
}


.text-container table tbody tr:nth-child(odd) {
    background-color: #f5f5f5;
}

.text-container table tbody tr:nth-child(odd) td:nth-child(1) {
    background-color: #f5f5f5;
}

.text-container table tbody tr:nth-child(even) {
    background-color: #ffffff;
}

.text-container table tbody tr:nth-child(even) td:nth-child(1) {
    background-color: #ffffff;
}

.text-container table.scroll tbody tr th:nth-child(1),
.text-container table.scroll tbody tr td:nth-child(1) {
    position: sticky;
    left: 0;
    /* min-width: 50%; */
    /* max-width: 150px; */
    z-index: 2;
}

.text-container table.scroll tbody tr th:first-child:before,
.text-container table.scroll tbody tr td:first-child::before {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    display: block;
    width: 20px;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
}

button, a {
    cursor: pointer;
}

.disclaimer .text-container p {
    padding-bottom: 18px;
}

footer .disclaimer .text-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.PrintButton {
    display: none;
}

.sub-links svg {
    position: relative;
    left: 0;
}

.sub-links:hover svg {
    left: 10px;
    transition: left 0.1s ease;
}
 
  