.performance .tabSelector .tabHeading.mobile .tabs {
    flex: 1 1 0%;
}

.performance .tabSelector .tabHeading .tabs.active {
    background-color: #EDE9F2;
    border: 2px solid #49247D;
    color: #49247D;
}

.performance .tabSelector .mobileTabHeading {
    height: 0;
    border: none;
    overflow: hidden;
}

.performance .tabSelector .mobileTabHeading.active {
    height: auto;
    border: inherit;
}

.performance .tabSelector .mobileSelectedTab.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.performance .tabSelector .mobileSelectedTab .arrowDown {
    display: block;
}
.performance .tabSelector .mobileSelectedTab.active .arrowDown {
    display: none;
}
.performance .tabSelector .mobileSelectedTab .arrowUp {
    display: none;
}
.performance .tabSelector .mobileSelectedTab.active .arrowUp {
    display: block;
}