.jobAdder .ja-job-list-container .ja-job-list {
    @apply flex flex-wrap gap-16 2xl:gap-32;
}

.jobAdder .ja-job-details {
    @apply w-full bg-purple-10 p-32 rounded-2xl;
}

.jobAdder .ja-job-list-container .ja-job-list .job {
    @apply w-full md:w-[48.25%] lg:w-[48.7%] md:max-w-[50%] bg-purple-10 p-32 rounded-2xl;
}

.jobAdder .text-container h2 {
    @apply text-lg lg:text-h4 pb-16 font-semibold text-purple-100 mt-0;
}

.jobAdder .text-container h2 a {
    @apply no-underline;
}

.jobAdder .text-container p {
    @apply pb-24;
}

.jobAdder .text-container .apply {
    @apply flex flex-col md:flex-row gap-16 md:gap-24 items-start md:items-center mt-32;
}

.jobAdder .text-container .ja-button {
    @apply flex justify-center items-center text-base md:text-md text-white rounded-lg 
    px-24 py-12 bg-purple-100 border-4 border-transparent cursor-pointer
    hover:bg-purple-hoverDark 
    focus:bg-purple-hoverDark focus:border-4 focus:border-purple-100 focus:border-opacity-20 
    active:bg-purple-pressedDark 
    disabled:bg-neutral-30 disabled:border disabled:border-neutral-50 disabled:text-neutral-60 disabled:pointer-events-none
    visited:bg-purple-pressedDark;
}

.jobAdder .text-container .back-link {
    @apply flex text-base md:text-md rounded-lg border-4 border-transparent text-black font-semibold 
    focus:border-4 focus:border-purple-100 focus:border-opacity-20
    active:text-purple-hoverDark;
}