
.logo img {
  width: 100%;
  /* height: 64px; */
}
.mega-menu {
  /* opacity: 1;
  visibility: visible;
  z-index: 900; */
  opacity: 0;
  visibility: visible;
  z-index: -900;
  left: 0;
  position: absolute;
  text-align: left;
  width: 100%;
  transition: all 0.15s linear 0s;
  margin-top: 34px;
  max-height: 33vw;
  overflow-y: scroll;
}
.mega-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.mega-menu::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.mega-menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #49247d;
}

/* #hoverable Class Styles */
.hoverable {
  position: static;
}

.hoverable > a:after {
  content: " ";
  background: url(../../../Assets/keyboard_arrow_down.png) no-repeat;
  font-size: 10px;
  padding-left: 6px;
  position: relative;
  top: -1px;
  width: 24px;
  height: 24px;
}
.hoverable:hover > a:after {
  content: " ";
  background: url(../../../Assets/keyboard_arrow_up.svg) no-repeat;
  font-size: 10px;
  padding-left: 6px;
  position: relative;
  top: -1px;
  width: 24px;
  height: 24px;
}
.hoverable:hover .mega-menu {
  opacity: 1;
  visibility: visible;
  z-index: 900;
}

/* #toggle Class Styles */

.toggleable > label:after {
  content: "\25BC";
  font-size: 10px;
  padding-left: 6px;
  position: relative;
  top: -1px;
}

.toggle-input {
  display: none;
}
.toggle-input:not(checked) ~ .mega-menu {
  opacity: 0;
  visibility: hidden;
  z-index: -900;
}

.toggle-input:checked ~ .mega-menu {
  opacity: 1;
  visibility: visible;
  z-index: 900;
}

.toggle-input:checked + label {
  color: white;
  background: rgb(3, 102, 114); /*@apply bg-teal-700 */
}

.toggle-input:checked ~ label:after {
  content: "\25B2";
  font-size: 10px;
  padding-left: 6px;
  position: relative;
  top: -1px;
}
/* Sub-Menu Mobile component styles */

.mobileMenu {
  height: 852px;
}
.mobileMenuWrapper {
  height: 743px;
}
.force-overflow {
  min-height: 743px;
}
/* Sub-Menu Mobile scroll styles */
.mobileMenuWrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.mobileMenuWrapper::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.mobileMenuWrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #49247d;
}
.submenuContaner {
  height: auto;
  box-shadow: none;
  position: relative;
  width: 100%;
  overflow: hidden;
  font-size: 18px;
  transform: translateX(-100%);
  transition: transform ease-in-out 0.2s;
  display: none;
}
.mobileSubMenu {
  position: static;
}
.mobileSubMenu > div > span {
  background-image: url("../../../Assets/keyboard_arrow_down.svg");
  background-repeat: no-repeat;
  background-position: center center;
}
.mobileSubMenu.open > div > span {
  background-image: url("../../../Assets/keyboard_arrow_up_white.svg");
  background-repeat: no-repeat;
  background-position: center center;
}
.mobileSubMenu.open .submenuContaner {
  opacity: 1;
  width: 100%;
  transform: translateX(0px);
  display: block;
}
.mobileSubMenu.open > div > span {
  background-color: #49247d;
}

/* .SearchBox {
  left: -704px;
  width: 186%;
} */
.textlink a {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #000;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  margin: 8px 0;
}
.textlink a:hover {
  text-decoration: underline;
}
.subMenulink a {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #000;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  /* mask-image: url('../../../Assets/arrowForward.svg'); 
  mask-repeat: no-repeat; */
  background-image: url("../../../Assets/quicklinkArrow.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  padding-left: 30px;
  transition: all .2s ease-in;
}
.subMenulink a:hover {
  background-position: 4px 0;
}
.brd{border: 1px solid red;}
.textGray {
  color: #999;
}