.dailyUnitPrice .products .tabHeading.mobile .tabs {
    flex: 1 1 0%;
}

.dailyUnitPrice .products .tabHeading .tabs.active {
    background-color: #EDE9F2;
    border: 2px solid #49247D;
    color: #49247D;
}

.dailyUnitPrice .products .mobileTabHeading {
    height: 0;
    border: none;
    overflow: hidden;
}

.dailyUnitPrice .products .mobileTabHeading.active {
    height: auto;
    border: inherit;
}

.dailyUnitPrice .products .mobileSelectedTab.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}


.dailyUnitPrice .displayOptions .tabHeading.mobile .tabs {
    flex: 1 1 0%;
}

.dailyUnitPrice .displayOptions .tabHeading .tabs.active {
    background-color: #EDE9F2;
    border: 2px solid #49247D;
    color: #49247D;
}

.dailyUnitPrice .displayOptions .mobileTabHeading {
    height: 0;
    border: none;
    overflow: hidden;
}

.dailyUnitPrice .displayOptions .mobileTabHeading.active {
    height: auto;
    border: inherit;
}

.dailyUnitPrice .displayOptions .mobileSelectedTab.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}