.pagination:focus{
    -webkit-box-shadow: 1px 1px 5px 0px rgba(73,36,125,1);
-moz-box-shadow: 1px 1px 5px 0px rgba(73,36,125,1);
box-shadow: 1px 1px 5px 0px rgba(73,36,125,1);
}
.pagination .active{
    background-color: #49247D;
    color: #FFFFFF
}

.ais-Hits-item {
    border-bottom: solid 1px rgb(194 194 194 / var(--tw-border-opacity));
}

.arrow * {
    font-size: 26px;
}

.ais-Pagination-item--disabled {
    color: rgb(194 194 194 / var(--tw-text-opacity));
}
