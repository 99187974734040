  .imageBlurMobile {
    position: absolute;
    height: 100px;
    width: 100%;
    top: -80px;
    left: 0px;
    background: #221535;
    background: linear-gradient(180deg, rgba(34, 21, 53, 0) -25%, #221535 75%);
    background: -webkit-linear-gradient(180deg, rgba(34, 21, 53, 0) -25%, #221535 75%);
    background: -moz-linear-gradient(180deg, rgba(34, 21, 53, 0) -25%, #221535 75%);
    background: -o-linear-gradient(180deg, rgba(34, 21, 53, 0) -25%, #221535 75%);
    background: linear-gradient(180deg, rgba(34, 21, 53, 0) -25%, #221535 75%);
  }

  .bannerTextMobile {
    background: #221535;
  }
  
  .imageBlur {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    background: #221535;
    background: -webkit-linear-gradient(273.77deg, rgba(34, 21, 53, 0) 35%, #221535 65%);
    background: -moz-linear-gradient(273.77deg, rgba(34, 21, 53, 0) 35%, #221535 65%);
    background: -o-linear-gradient(273.77deg, rgba(34, 21, 53, 0) 35%, #221535 65%);
    background: linear-gradient(273.77deg, rgba(34, 21, 53, 0) 35%, #221535 65%);
  }

  @media only screen and (min-width: 1280px) {
    .heroBanner {
      background-size: 80% !important;
    }
    .imageBlur {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0px;
      left: 0px;
      background: #221535;
      background: -webkit-linear-gradient(273.77deg, rgba(34, 21, 53, 0) 30%, #221535 60%);
      background: -moz-linear-gradient(273.77deg, rgba(34, 21, 53, 0) 30%, #221535 60%);
      background: -o-linear-gradient(273.77deg, rgba(34, 21, 53, 0) 30%, #221535 60%);
      background: linear-gradient(273.77deg, rgba(34, 21, 53, 0) 30%, #221535 60%);
    }
  }

  @media only screen and (min-width: 1400px) {
    .heroBanner {
      background-size: cover;
    }
    .imageBlur {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0px;
      left: 0px;
      background: #221535;
      background: -webkit-linear-gradient(273.77deg, rgba(34, 21, 53, 0) 35%, #221535 65%);
      background: -moz-linear-gradient(273.77deg, rgba(34, 21, 53, 0) 35%, #221535 65%);
      background: -o-linear-gradient(273.77deg, rgba(34, 21, 53, 0) 35%, #221535 65%);
      background: linear-gradient(273.77deg, rgba(34, 21, 53, 0) 35%, #221535 65%);
    }
  }
  